
import React, { useState } from "react";
import { styled } from '@mui/material/styles';
import _ from "lodash";

import { getImage } from "gatsby-plugin-image";
import { BgImage } from "gbimage-bridge"
import { Link } from "gatsby"
import { getEntityUrl } from "../../util";

import "../wrm-hp/app.css";

import "./richtext.css";
import "./m5k.css";
import "../m5k-hp/m5k-hp.css"
import "./collapsible.css";

import Menu from "./menu";
import EntityKeypad from "../../components/Keypad";

import Wrapper from "../../components/Wrapper";
import ComponentsWrapper from "../../components/ComponentsWrapper";

import List from "./list";

const PREFIX = 'EntityPage';

const classes = {
    root: `${PREFIX}-root`,
};

const Root = styled('div')((
    {
        theme
    }
) => ({
    [`& .${classes.root}`]: {
        padding: '0',
        textDecoration: 'none',
        fontFamily: 'opensans, Verdana, Helvetica, Arial, sans-serif',
    },

}));

const HeadlineDiv = styled('div')(({ theme }) => ({
    padding: 0,
    fontSize: 19,
    lineHeight: 1,
    textTransform: 'none',
    fontWeight: 700,
    color: '#000',
    margin: '12px 8px 4px',
}));

HeadlineDiv.defaultProps = {"data-id": "HeadlineDiv"};

const BildbuttonLink = styled(Link)(() => ({
    display: 'block',
    position: 'absolute',
    fontFamily: 'opensans, Verdana, Helvetica, Arial, sans-serif',
    fontStretch: 'normal',
    font: '15px opensans, Verdana, Helvetica, Arial, sans-serif',
    /* font-family: 'openlight', */
    bottom: '12px',
    left: '10px',
    whiteSpace: 'nowrap',
    padding: '8px 15px',
    background: 'hsla(0, 0%, 0%, 0.27)',
    fontSize: '16px',
    color: '#ffffff!important',
    border: '1px solid #FFF',
    letterSpacing: '0.06em',
    textDecoration: 'none!important',
}));

const EntityPage = (props) => {
    const { entity, config, selectedModule, pageContext } = props;

    const { defaultLocale } = pageContext;

    const image = entity.largeImage ? getImage(entity.largeImage.localFile) : null;
    const headline = (entity.shortDesc || '').replace(/(?:\r\n|\r|\n)/g, '<br />');

    const [keypadShown, setKeypadShown] = useState(config?.showKeypadFirst || false);
    const keypadToggle = () => {
        setKeypadShown(!keypadShown);
    };

    return <Root className="m5k-list" id={entity.identifier}>
        <Wrapper {...props} menu={Menu} showLanguageSelector={false} title={entity.title} keypadSwitch={config?.showKeypad || false} keypadShown={keypadShown} onKeypad={keypadToggle}>
            {keypadShown ?
                <EntityKeypad {...{ entity }} {...props} /> :

                <div className={classes.root}>

                    {image !== null &&
                        <div style={{ width: '100%', height: '210px'}} className={entity.title}>
                            <BgImage image={image} alt={headline} layout="fullWidth" style={{ width: '100%', height: '100%' }}>
                                {config.headerLink !== undefined && config.headerLink !== null &&
                                    <BildbuttonLink className={` ${classes.bildbutton}`}
                                        to={getEntityUrl({ entity: config.headerLink, defaultLocale })}>{config.headerLink.title}</BildbuttonLink>
                                }
                                <div className="islam fromleft highani">Orient</div>
                                <div className="ozean fromleft highani">Ozeanien</div>
                                <div className="nordamerika fromright highani">Nordamerika</div>
                                <div className="myanmar fromleft highani">Myanmar</div>
                                <div className="afrika fromleft highani">Afrika</div>
                                <div className="buddha fromright highani">Buddha-Saal</div>
                                <div className="munds fromright highani">Lateinamerika</div>

                                <div className="en_islam fromleft highani">Orient</div>
                                <div className="en_ozean fromleft highani">Oceania</div>
                                <div className="en_nordamerika fromright highani">North America</div>
                                <div className="en_myanmar fromleft highani">Myanmar</div>
                                <div className="en_afrika fromleft highani">Africa</div>
                                <div className="en_buddha fromright highani">Buddha-Hall</div>
                                <div className="en_munds fromright highani">Latin America</div>

                            </BgImage>
                        </div>
                    }
                    {headline?.length > 0 && <HeadlineDiv>{headline}</HeadlineDiv>}

                    <ComponentsWrapper {...props} />

                    <List {...props} />
                </div>
            }
        </Wrapper>
    </Root>;
};

export default EntityPage;
